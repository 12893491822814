/*global Component,axios */
class addressesManagerComponent extends Component {

    static name() {
        return "addressesManagerComponent";
    }

    static componentName() {
        return "addressesManagerComponent";
    }

    getProps() {
        return {
            showAddressList:{
                type:Boolean,
                default:true
            }
        };
    }

    data() {
        return {
            errorTimeout:null,
            isloading: false,
            confirmAddress:false,
            customerAddresses: [],
            omitWatch:false,
            selectedAddress: {
                Code: null,
                Name: '',
                Address: '',
                Country: '',
                ProvinceCode: '',
                ZipCode:'',
                City: '',
                Locality:'',
                LocalityCode:'',
                Street:'',
                StreetNumber:'',
                Floor:'',
                Apartment:"",
                Latitude:null,
                Longitude:null,
                normalize:false,
                CustomLocation:false
            },
            Latitude:null,
            Longitude:null,
            alertErrorAddress: null
        };
    }

    beforeRouteUpdate(to, from, next) {
        this.clearForm();
        next();
    }

    mounted() {
        return function () {
            this.subscribeEvent("address-relocation",this.confirmAddressTrigger);
            this.subscribeEvent("address-relocation-cancel",this.triggerSaveAction);
            this.loadCustomerAddresses();
            this.$store.dispatch('updateSelectLinkto', {
                table: "editAddress_Country",
                value: this.$store.getters.getOurSettingsCountry
            }).then(()=>{
                this.updateSelectValues('Country');
            });
        };
    }

    getWatch() {
        return {
            '$store.state.CustomerAddresses': function () {
                this.clearForm();
            },
            'selectedAddress.ProvinceCode': function (val,oldval) {
                if(!this.omitWatch)
                    this.selectedAddress.normalize = true;
            },
            'selectedAddress.City': function (val,oldval) {
                if(!this.omitWatch)
                    this.selectedAddress.normalize = true;
            },
            'selectedAddress.Street': function (val,oldval) {
                this.buildAddress();
                if(!this.omitWatch)
                    this.selectedAddress.normalize = true;
            },
            'selectedAddress.StreetNumber': function (val,oldval) {
                this.buildAddress();
                if(!this.omitWatch)
                    this.selectedAddress.normalize = true;
            },
            'selectedAddress.Floor': function () {
                this.buildAddress();
            },
            'selectedAddress.Apartment': function () {
                this.buildAddress();
            }
        };
    }

    getComputed() {
        return {
            editableByCountry: function () {
                return  false;//this.selectedAddress.Country !== this.$store.getters.getOurSettingsCountry;
            },
            showCountry:function (){
                return this.$store.getters.showCountryAddress;
            },
            getCustomerAddresses: function () {
                this.customerAddresses = this.$store.getters.getCustomerAddresses;
                return this.customerAddresses;
            },
            provinceFilter() {
                let filter = {};
                if (this.selectedAddress.Country)
                    filter['Country'] = this.selectedAddress.Country;
                else 
                    filter['internalId__gte'] = 0;
                return filter;
            },
            cityFilter() {
                let filter = {};
                if (this.selectedAddress.ProvinceCode)
                    filter['Province'] = this.selectedAddress.ProvinceCode;
                else 
                    filter['internalId__gte'] = 0;
                return filter;
            },
            zipCodeFilter() {
                let filter = {};
                if (this.customer.LocalityCode)
                    filter['LocalityCode'] = this.customer.LocalityCode;
                return null;
            },
        };
    }

    getMethods() {
        return {
            buildAddress:this.buildAddress,
            checkAddressData:this.checkAddressData,
            loadCustomerAddresses: this.loadCustomerAddresses,
            updateSelectValues: this.updateSelectValues,
            normalizeAddress: this.normalizeAddress,
            triggerSaveAction: this.triggerSaveAction,
            confirmAddressTrigger: this.confirmAddressTrigger,
            activeDeliveryAddress: this.activeDeliveryAddress,
            activeAddress: this.activeAddress,
            registerNewAddress: this.registerNewAddress,
            editAddress: this.editAddress,
            disableAddress: this.disableAddress,
            clearForm: this.clearForm,
            clearError:this.clearError,
            viewInMap: this.viewInMap,
            locationChange: this.locationChange,
            confirmLocation:this.confirmLocation
        };
    }

    buildAddress(){
        let newAddress = '';
        if (this.selectedAddress.Street) {
            newAddress = this.selectedAddress.Street + " ";
        }
        if (this.selectedAddress.StreetNumber) {
            newAddress += this.selectedAddress.StreetNumber + " ";
        }
        if (this.selectedAddress.Floor)
            newAddress += this.selectedAddress.Floor + " ";
        if (this.selectedAddress.Apartment)
            newAddress += this.selectedAddress.Apartment + " ";

        this.selectedAddress.Address = newAddress;
    }

    loadCustomerAddresses() {
        this.isloading = true;
        this.$store.dispatch('loadCustomerAddresses');
        this.isloading = false;
    }

    updateSelectValues(field) {
        if (field === 'Country')
            this.selectedAddress.Country = this.$store.getters.getSelectLinkToValue('editAddress_Country');
        if (field === 'Province')
            this.selectedAddress.ProvinceCode = this.$store.getters.getSelectLinkToValue('editAddress_Province');
        if (field === 'City')
            this.selectedAddress.City = this.$store.getters.getSelectLinkToValue('editAddress_City');
        if (field === 'Locality')
            this.selectedAddress.LocalityCode = this.$store.getters.getSelectLinkToValue('editAddress_Locality');
        if (field === 'ZipCode')
            this.selectedAddress.ZipCode = this.$store.getters.getSelectLinkToValue('editAddress_ZipCode');
    }

    async normalizeAddress(eventAddress){
        let address = this.selectedAddress;
        if(eventAddress)
            address = eventAddress;
        let response = await axios.post('/ecommerce/api/getLocation',{record:address});
        if(response.data.success) {
            if(!eventAddress) {
                this.selectedAddress = response.data.record;
                this.selectedAddress.normalize = false;
            }
            eventAddress = response.data.record;
            eventAddress.normalize = false;
        }
        return eventAddress;
    }

    async triggerSaveAction(eventAddress){
        let dataIsValid = this.checkAddressData();
        if(!dataIsValid)
            return null;

        let address = this.selectedAddress;
        if(eventAddress)
            address = eventAddress;
        let openMap = address.normalize;
        if(!address.Longitude || !address.Latitude || address.normalize) {
            this.selectedAddress = address;
            await this.normalizeAddress();
        }
        if(openMap) {
            this.viewInMap();
        }
        else {
            if (this.activeDeliveryAddress(this.selectedAddress.Code))
                this.editAddress();
            else
                this.registerNewAddress();
        }
    }

    confirmAddressTrigger(address){
        if(address.Code === this.selectedAddress.Code && address.Address === this.selectedAddress.Address) {
            this.selectedAddress = address;
            this.triggerSaveAction(address);
        }
    }

    activeDeliveryAddress(addressCode) {
        if (this.selectedAddress.Code != null) {
            if (this.selectedAddress.Code === addressCode)
                return true;
        }
        return false;
    }

    activeAddress(addressCode) {
        if (this.selectedAddress.Code !== addressCode) {
            //this.selectedAddress.Code = addressCode;
            let select = this.customerAddresses.filter(x => x.Code === addressCode);
            if(select.length > 0) {
                this.omitWatch = true;
                this.selectedAddress = window.__lodash__.cloneDeep(select[0]);
                setTimeout(()=> this.omitWatch = false , 500);
            }
        } else {
            this.clearForm();
        }
    }

    clearError(){
        this.alertErrorAddress = null;
        window.clearTimeout(this.errorTimeout);
        this.errorTimeout = null;
    }

    checkAddressData() {
        this.alertErrorAddress = null;
        this.selectedAddress.Name = this.selectedAddress.Address;

        if(this.errorTimeout)
            window.clearTimeout(this.errorTimeout);

        if (!this.selectedAddress.Street)
            this.alertErrorAddress = ['Please fill the field {{$1}}', "Street"];
        else if (!this.selectedAddress.StreetNumber)
            this.alertErrorAddress = ['Please fill the field {{$1}}', "Number"];
        else if (!this.selectedAddress.ProvinceCode)
            this.alertErrorAddress = ['Please fill the field {{$1}}', "Province"];
        else if (!this.selectedAddress.Locality)
            this.alertErrorAddress = ['Please fill the field {{$1}}', "Locality"];
        else if (!this.selectedAddress.City)
            this.alertErrorAddress = ['Please fill the field {{$1}}', "City"];
        else if (!this.selectedAddress.ZipCode)
            this.alertErrorAddress = ['Please fill the field {{$1}}', "ZipCode"];

        if(this.alertErrorAddress != null)
            this.errorTimeout = window.setTimeout(this.clearError,8000);

        return this.alertErrorAddress === null;
    }

    async registerNewAddress() {
        this.checkAddressData();
        if (!this.alertErrorAddress) {
            this.$store.dispatch('AddAddressCustomer', this.selectedAddress).then((response)=>{
                this.clearForm();
            });
        }
    }

    async editAddress() {
        this.alertErrorAddress = null;
        this.selectedAddress.Name = this.selectedAddress.Address;
        this.checkAddressData();

        if (!this.alertErrorAddress) {
            this.$store.dispatch('editAddressCustomer', this.selectedAddress).then((response)=>{
                this.clearForm();
            });
        }
    }

    disableAddress() {
        this.$store.dispatch('disableAddress', this.selectedAddress);
    }

    clearForm() {
        this.omitWatch = true;
        if(this.selectedAddress) {
            for(let addressField of Object.keys(this.selectedAddress))
                this.selectedAddress[addressField] = '';
    
            this.selectedAddress.Code = null;
            this.selectedAddress.Country = this.$store.getters.getOurSettingsCountry;
            this.selectedAddress.normalize = false;
            this.selectedAddress.CustomLocation = false;
            setTimeout(()=> this.omitWatch = false , 500);
        }
    }

    viewInMap(){
        this.confirmAddress = true;
        this.emitEvent("addressNeedConfirmations",this.selectedAddress);
    }

    locationChange(latitude,longitude){
        this.Latitude = latitude;
        this.Longitude = longitude;
    }

    confirmLocation(){
        this.selectedAddress.Latitude = this.Latitude;
        this.selectedAddress.Longitude = this.Longitude;
        this.selectedAddress.normalize = false;
        this.selectedAddress.CustomLocation = true;
    }


    getTemplate() {
        return `<div class="container-fluid">
                  <div class="row">
                    <div class="col-12" v-bind:class="{'col-sm-4':showAddressList}" >
                        <h5 v-if="showAddressList" class="title" >{{tr('Add / Edit')}}</h5>
                        <div class="form-group" >
                           <label for="selectedAddress" class="form-label">{{tr('Street')}}</label>
                           <input id="selectedAddress" v-model="selectedAddress.Street" type="text" class="form-control" aria-autocomplete="none" autocomplete="none" />
                        </div>
                        <div class="form-group" >
                           <label for="selectedAddress" class="form-label">{{tr('Number')}}</label>
                           <input id="selectedAddress" v-model="selectedAddress.StreetNumber" type="text" class="form-control" aria-autocomplete="none" autocomplete="none" />
                        </div>
                        <div class="row">
                            <div class="form-group col-6" >
                               <label for="selectedAddress" class="form-label">{{tr('Floor')}} ({{tr('Optional')}})</label>
                               <input id="selectedAddress" v-model="selectedAddress.Floor" type="text" class="form-control" aria-autocomplete="none" autocomplete="none" />
                            </div>
                            <div class="form-group col-6" >
                                <label for="selectedAddress" class="form-label">{{tr('Apartment')}} ({{tr('Optional')}})</label>
                               <input id="selectedAddress" v-model="selectedAddress.Apartment" type="text" class="form-control" aria-autocomplete="none" autocomplete="none" />
                            </div>
                        </div>
                        <div class="form-group" v-if="showCountry">
                            <label for="customerCountry" class="form-label">{{tr('Country')}}</label>
                            <selectLinkToComponent recordKey="editAddress"  :table="'Country'" :paste="'Code'" :showField="'Name'" :selected="selectedAddress.Country" v-on:update-value="updateSelectValues('Country')"> </selectLinkToComponent>
                        </div>
                        <div class="form-group">
                            <label class="form-label">{{tr("Province")}}</label>
                            <selectLinkToComponent recordKey="editAddress" :table="'Province'" :paste="'Code'" :showField="'Name'" :filters="provinceFilter" :selected="selectedAddress.ProvinceCode"  :editable="editableByCountry" @update-value="updateSelectValues('Province')"> </selectLinkToComponent>
                        </div>
                        <div class="form-group">
                            <label class="form-label">{{tr('City')}}</label>
                            <selectLinkToComponent recordKey="editAddress"  :table="'City'" :paste="'Code'" :showField="'Code'"  :filters="cityFilter" :selected="selectedAddress.City" :editable="editableByCountry" v-on:update-value="updateSelectValues('City')"> </selectLinkToComponent>
                        </div>
                        <div class="form-group">
                            <label class="form-label">{{tr('Locality')}}</label>
                            <input id="selectedLocality" v-model="selectedAddress.Locality" type="text" class="form-control" aria-autocomplete="none" autocomplete="none" />
                        </div>
                        <div class="form-group">
                            <label for="selectedZipCode" class="form-label">{{tr('ZipCode')}}</label>
                            <input id="selectedZipCode" v-model="selectedAddress.ZipCode" type="text" class="form-control" aria-autocomplete="none" autocomplete="none" />
                        </div>
                        <hr>
                        <div class="col-sm-12" v-if="alertErrorAddress">
                            <div ref='errorRegisterFrom' class="alert alert-danger alert-dismissible fade show" role="alert" >
                               <strong>{{tr(alertErrorAddress)}}!</strong>
                               <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="alertErrorAddress = null">
                                <span aria-hidden="true">&times;</span>
                               </button>
                            </div>
                        </div>
                        <div class="view-mode text-right text-sm-left mb-3 ml-1 mr-2" >
                            <button key="address-save"  type="submit" :title="tr('Save')" class="btn btn-custom" @click="triggerSaveAction()"><i class="icon fas fa-save"></i></button>
                            <button key="address-disabled" type="submit" :title="tr('Close')" :class="['btn btn-custom',{disabled:!activeDeliveryAddress(selectedAddress.Code)}]" @click="disableAddress"><i class="icon fas fa-trash"></i></button>
                            <button key="address-viewmap" v-if="selectedAddress['normalize']" type="submit" :title="tr('View Map')" :class="['btn btn-custom',{disabled:!activeDeliveryAddress(selectedAddress.Code)}]" @click="viewInMap()"><i class="icon fas fa-map-marker-alt"></i></button>
                        </div>
                    </div>
                    <div v-if="showAddressList" class="col-12 col-sm-8">
                        <div class="container">
                            <h5 class="title">{{tr('My Addresses')}} ({{getCustomerAddresses.length}})</h5>
                            <div class="row">
                              <template v-for="address in getCustomerAddresses">
                                  <div :key="address.Code" class="col-12 col-md-6">
                                      <div class="address-card address-manager my-2" :class="{selected:activeDeliveryAddress(address.Code)}" v-on:click="activeAddress(address.Code)" >
                                           <strong id="deliveryLabel">{{address.Name}}</strong>
                                            <p>{{address.Address}} </p>
                                            <p>{{address.Province}} </p>
                                            <p>{{address.City}} </p>
                                            <p>{{address.Locality}} </p>
                                            <p>{{address.ZipCode}} </p>
                                      </div>
                                   </div>
                                </template>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>`;
    }
}

addressesManagerComponent.registerComponent();
